// Variable overrides

// $theme-colors: (
//     "primary": #12b3c7
// );


//CoreUIの変数

$white:  #fff;
$black:  #000015;
$gray-100:   #F8F7F2;
$primary:       #298A00;
$secondary:       #3c4b64;
$danger:       #e55353;
$success:       #5AB5B2;
$info:       #80bb67;
$warning:       #FFAE00;

$table-dark-bg: #478384;

$light-green: #28A800;

$menu-green: $primary;
$menu-black: $black;
$menu-white: $white;

$sidebar-width: 160px;
$sidebar-nav-icon-width: 35px;
$sidebar-minimized-width: 35px;

$sidebar-dark-color:  #000015;
$sidebar-light-color:  #000015;
$sidebar-dark-brand-color:  #fff;
$sidebar-light-brand-color:     #fff;
$sidebar-dark-nav-link-color: #000015;
$sidebar-light-nav-link-color: #000015;
$sidebar-dark-nav-link-icon-color: $menu-green;
$sidebar-light-nav-link-icon-color: $menu-green;
$sidebar-dark-nav-dropdown-color: #000015;

$sidebar-dark-nav-dropdown-color: #000015;
$sidebar-light-nav-dropdown-color: #000015;
$sidebar-dark-nav-dropdown-indicator-color: #000015;
$sidebar-light-nav-dropdown-indicator-color: #000015;
$sidebar-dark-nav-link-hover-color: #fff;
$sidebar-light-nav-link-hover-color: #fff;
$sidebar-dark-nav-link-active-color: #fff;
$sidebar-light-nav-link-active-color: #fff;
$sidebar-dark-minimizer-indicator-color: #E5E5E5;

$sidebar-dark-bg:  #F8F7F2;
$sidebar-light-bg:  #F8F7F2;
$sidebar-dark-brand-bg:     $gray-100;
$sidebar-light-brand-bg:     $gray-100;
$sidebar-dark-nav-dropdown-bg: #F8F7F2;
$sidebar-light-nav-dropdown-bg: #F8F7F2;
$sidebar-dark-nav-link-hover-bg: #298A00;
$sidebar-light-nav-link-hover-bg: #298A00;
$sidebar-dark-nav-link-active-bg: #298A00;
$sidebar-light-nav-link-active-bg: #298A00;
$sidebar-dark-minimizer-bg: #298A00;
$sidebar-light-minimizer-bg: #298A00;
$sidebar-dark-minimizer-hover-bg: #298A00;
$sidebar-light-minimizer-hover-bg: #298A00;

//カスタム変数

$custom-sidebar-background-color: #F8F7F2;

$custom-top-button-background-color: #298A00E6;

// $sidebar-dark-brand-bg:     #28A800;
// $sidebar-light-brand-bg:     #28A800;

// //カスタム変数

// $custom-sidebar-background-color: #298A00;

// $custom-top-button-background-color: #298A00A6;

:root{
    --chatbox-background-color: #F4F4F4;
    --chatelement-background-color: #CCFECC;
}
