// Here you can add other styles
body {
  font-family: "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
}


.c-main{
  padding-top: 0.5rem;
  overflow-x: auto;
}


.container-fluid{
  min-width: 1000px;
}


@media (min-width: 992px){
  .modal-lg, .modal-xl {
    max-width: 900px;
  }
}


@media (min-width: 1200px){
  .modal-xl {
    max-width: 1200px;
  }
}

@media (min-width: 1400px){
  .modal-xl {
    max-width: 1300px;
  }
}

@media (min-width: 1500px){
  .modal-xl {
    max-width: 1400px;
  }
}
.fix-table-container table{
  table-layout: fixed;
}

.three-points-td{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.three-points-td-min-150{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 150px;
}

.react-datepicker-wrapper{
  display: block;
}

.white-read-only:read-only {
  background-color: white;
}

.rce-mbox{
  width: 70%;
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

.rce-mbox-right{
  background-color: var(--chatelement-background-color) !important;
}

.rce-container-mlist{
  background-color: var(--chatbox-background-color);
  height: 450px;
}

.rce-mbox-text{
  white-space: pre-wrap;
}


//カスタムテーマ
.c-sidebar {
  color: $black;
  background: $custom-sidebar-background-color;
  border-right: 1px solid $primary;
}

.c-show {
  .c-sidebar-nav-dropdown-toggle {
    .c-sidebar-nav-icon {
      color: #fff;
    }
  }
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: $menu-white !important;
  background-color: $menu-green !important;
}

.c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-toggle::after {
  background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='#{$white}' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"), "#", "%23");
  transform: rotate(-90deg);
}

.c-sidebar-nav-item .c-sidebar-nav-link:hover {
  color: $menu-white !important;
  background-color: $menu-green !important;
}
.c-sidebar-nav-item .c-sidebar-nav-link.c-active {
  color: $menu-white !important;
  background-color: $menu-green !important;
}

.btn-info {
  color: $white;
  background-color: $info;
  border-color: $info
}

.btn-info:disabled {
  color: $white;
}

.btn-warning {
  color: $white;
}

.btn-warning:disabled {
  color: $white;
}


.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}




@media (min-width:0px){
  .summary-column-filter{
    flex: 0 0 16.6666666666666%;
  }
}


@media (min-width: 1400px){
  .summary-column-filter{
    flex: 0 0 12%;
  }
}


.date-picker-wrapper > .react-datepicker-wrapper {
  width: 100%;
}


.white-readonly .form-control:disabled,.white-readonly .form-control[readonly] {
  background-color: white;
  opacity: 1;
}


.page-top {
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;

  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 100;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $custom-top-button-background-color;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.page-top::before {
  content: "";
  width: 12px;
  height: 12px;
  margin-bottom: -6px;
  border-top: solid 3px #fff;
  border-right: solid 3px #fff;
  transform: rotate(-45deg);
}

.page-top:hover {
  transform: scale(1.1);
}


.split {
  display: flex;
  flex-direction: row;
}

.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}


.elink-logo-sidebar {
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
  transition: all 500ms 0s ease;
}

.elink-logo-sidebar img{
  width: 90%;
}

.c-sidebar-minimized .elink-logo-sidebar {
  position: absolute;
  bottom: 50px;
  width: 35px;
  margin-bottom: 0px;
  transition: all 500ms 0s ease;
  img {
    width: 70%;
  }
}

.c-sidebar-brand {
  border-bottom: 1px solid $primary;
  img {
    width: 90%;
    margin: 10px 0 10px 0;
  }
}

.c-sidebar-minimized {
  .c-sidebar-brand {
    img {
      width: 90%;
      margin: 2px 0 2px 0;
    }
  }
}

.elink-logo-col {
  display: flex;
  justify-content: end;
}

.elink-logo-container {
  height: 30px;
  img {
    height: 30px;
  }
}

.elink-form-group {
  display: flex;
  align-items: center;

  .elink-logo-form-group {
    margin-left: 10px;
    height: 20px;
    img {
      height: 20px;
    }
  }
}

.organization-follow-checkbox::before {
  background-color: #d8dbe0;
}

.summary-toggle-button {
  background-color: $gray-100 !important;
  border-color: $gray-100 !important;
  box-shadow: 0 0 0 0 !important;

  &:focus,
  &.focus,
  &:hover,
  &.hover {
    background-color: $gray-100 !important;
    border-color: $gray-100 !important;
    box-shadow: 0 0 0 0 !important;
  }
}

.btn-outline-primary {
  &:hover,
  &.hover {
    background-color: $custom-sidebar-background-color !important;
    border-color: $primary !important;
    color: $primary !important;
  }
}


.text-light-green {
  color: $light-green !important;
}

.message-list-midoku .rce-mbox-title span::after{
  content: "未読";
  margin-left:12px;
  color: chocolate;
}
.message-list-kidoku .rce-mbox-title span::after{
  content: "既読";
  margin-left:12px;
  color:glen;
}

.date-picker-container {
  flex-direction: column;
  display: flex;
  padding: 0px;
  border: 0px;
  width: 100%;
  align-items: flex-end;

  a{
    font-size: 0.7rem;
  }
}

.date-picker-span {
  margin-bottom: 0.7rem;
}

.collapse-card-header{
  display: flex;
  .collapse-card-header-icon{
    flex-grow: 1;
    text-align: right;
  }
  &:hover,
  &.hover {
    background-color: $gray-100;
    cursor: pointer;
  }
}

.select-button-container{

  .select-button-row{
    margin-top: 5px;
    button {
      margin-right: 5px;
    }
  }
}

.select-button-container-small{

  .select-button-row{
    margin-top: 0px;
    button {
      margin-right: 5px;
    }
  }
}

.medical_interview_container{
  margin-left: 0px;
  margin-right: 0px;
  border: 1px solid #d3d3d3;
  margin-bottom: 10px;;
  .medical_interview_title{
    background-color: #ced2d8;
  }
  .medical_interview_content{
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .no-padding{
    padding: 0;
  }
  .no-padding-right{
    padding-right: 0;
  }

  @media (max-width: 1400px){
    .no-padding{
      padding-left: 15px;
    }
  }
}


.reception-time-select{
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  margin-top: 5px;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
  .reception-time-select-div{
    display: flex;
    align-items: center;
  }
}


@media (min-width: 1400px){
  .reception-time-select{
    flex: 0 0 14.2857143%;
    max-width: 14.2857143%;
    margin-bottom: 0;
  }
}

.reception-time-select-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  a {
    font-size: 0.65rem;
  }
}


.dispensing-status-container-on{
  margin: 0 20px 0 0px;
  background-color: $info;
  color: $white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dispensing-status-container-off{
  margin: 0 20px 0 0px;
  background-color: $warning;
  color: $white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tippy-content{
  max-width: 1000px;
  background-color: rgba(0,0,0,0);
  padding: 10px 18px !important;
}

.dispensing-tooltip{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: large;
}

// Pop-upのカスタムスタイル
.Toastify__toast-theme--light{
  border: 3px solid $info;
  background-color: #FEFF99 !important;
  border-radius: 10px !important;
}


//React Calendar customization

.react-calendar{
  height: 215px;
}

.react-calendar__navigation {
  display: flex;
  height: 30px !important;
  margin-bottom: 0 !important;
}

.react-calendar__navigation__prev2-button{
  display: none;
}

.react-calendar__navigation__prev-button{
  display: none;
}

.react-calendar__navigation__next-button{
  display: none;
}

.react-calendar__navigation__next2-button{
  display: none;
}

.react-calendar__navigation__label{
  pointer-events: none;
}

.react-calendar__tile {
  padding: 5px 6.6667px !important;
}

.tile-holiday{
  background-color: #a9a9a9 !important;
  color: white !important;
}

.tile-holiday:hover{
  background-color: #a9a9a9 !important;
  color: white !important;
  opacity: 0.5;
}

.tile-weekend{
  background-color: white !important;
  color: black !important;
}

.tile-weekend:hover{
  background-color: #E5E5E5 !important;
  color: black !important;
}

.tile-weekday{
  background-color: white !important;
  color: black !important;
}

.tile-weekday:hover{
  background-color: #E5E5E5 !important;
  color: black !important;
}

/*

.tile-regular-holiday{
  background-color: white !important;
  color: chocolate !important;
}

.tile-regular-holiday:hover{
  background-color: #E5E5E5 !important;
  color: chocolate !important;
}

*/

.tile-national-holiday{
  background-color: chocolate !important;
  color: white !important;
}

.tile-national-holiday:hover{
  background-color: chocolate !important;
  color: white !important;
  opacity: 0.5;
}

.tile-regular-holiday{
  background-color: #a9a9a9 !important;
  color: white !important;
}

.tile-regular-holiday:hover{
  background-color: #a9a9a9 !important;
  color: white !important;
  opacity: 0.5;
}

.calendar-grid{
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 2.5px;
  padding-right: 2.5px;
}

@media (max-width: 1600px){
  .calendar-grid{
    flex: 0 0 33.333333333333%;
    max-width: 33.333333333333%;
    padding-left: 2.5px;
    padding-right: 2.5px;
  }
}

.reception-status-stat{
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 1rem;
  font-weight: bold;
}

.reception-status-stat-1{
  flex: 0 0 20%;
  max-width: 20%;
  margin-bottom: 10px;
}

.reception-status-stat-2{
  flex: 0 0 20%;
  max-width: 20%;
  margin-bottom: 10px;
}

.reception-status-stat-3{
  flex: 0 0 30%;
  max-width: 30%;
  margin-bottom: 10px;
}

.reception-status-stat-4{
  flex: 0 0 30%;
  max-width: 30%;
  margin-bottom: 10px;
}

.dispensing-status-container-left{
  flex: 0 0 30%;
  max-width: 30%;
}


.dispensing-status-container-middle{
  flex: 0 0 50%;
  max-width: 50%;
}

.dispensing-status-container-right{
  flex: 0 0 20%;
  max-width: 20%;
}


.dispensing-status-container-right-switch{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

.dispensing-status-container-time{
  font-size: 0.875rem;
  margin-top: 5px;
  margin-bottom: 5px;
}


@media (max-width: 1450px){

  .dispensing-status-container-time{
    font-size: 0.7rem;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}


@media (max-width: 1300px){
  .dispensing-status-container-right-switch{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px;
  }

  .dispensing-status-container-time{
    font-size: 0.5rem;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}


.dispensing-detail-button-row-left{
  flex: 0 0 50%;
  max-width: 50%;
}

.dispensing-detail-button-row-right{
  flex: 0 0 50%;
  max-width: 50%;
}

@media (max-width: 1500px){
  .dispensing-detail-button-row-left{
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .dispensing-detail-button-row-right{
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 1250px){
  .dispensing-detail-button-row-left{
    flex: 0 0 45%;
    max-width: 45%;
  }
  
  .dispensing-detail-button-row-right{
    flex: 0 0 55%;
    max-width: 55%;
  }
}


.medical_interview_grid_item{
  flex: 0 0 8.3333333333333333%;
  max-width: 8.3333333333333333%;
  padding-right: 0;
}

.medical_interview_grid_item_other{
  flex: 0 0 83.333333333333333%;
  max-width: 83.333333333333333%;
  padding-right: 0;
}

.medical_interview_grid_item_other_long{
  flex: 0 0 91.666666666666666%;
  max-width: 91.666666666666666%;
  padding-right: 0;
}

@media (max-width: 1600px){
  .medical_interview_grid_item{
    flex: 0 0 10%;
    max-width: 10%;
    padding-right: 0;
  }
  
  .medical_interview_grid_item_other{
    flex: 0 0 80%;
    max-width: 80%;
    padding-right: 0;
  }

  .medical_interview_grid_item_other_long{
    flex: 0 0 90%;
    max-width: 90%;
    padding-right: 0;
  }
}

@media (max-width: 1400px){
  .medical_interview_grid_item{
    flex: 0 0 16.66666666666%;
    max-width: 16.66666666666%;
    padding-right: 0;
  }
  
  .medical_interview_grid_item_other{
    flex: 0 0 66.66666666666%;
    max-width: 66.66666666666%;
    padding-right: 0;
  }

  .medical_interview_grid_item_other_long{
    flex: 0 0 83.3333333333333%;
    max-width: 83.3333333333333%;
    padding-right: 0;
  }
}


.dp-detail-group{
  border: 1px solid #d8dbe0;
  margin-left: 7px;
  margin-right: 7px;
  padding: 8px;
  border-radius: 10px 0 10px 10px;
  margin-top: 25px;
}

.dp-detail-group-button-area{
  position: relative;
  button{
    position: absolute;
    right: 15px;
    top: -25px;
    :disabled{
      background-color: #75B35A;
      border-color: #75B35A;
      opacity: 1;
    } 
  }

  button.disabled{
    background-color: #75B35A;
    border-color: #75B35A;
    opacity: 1;
  }
}

.dp-detail-group-button-area-recall{
  position: relative;
  .dp-detail-group-button-area-recall-button{
    position: absolute;
    right: 15px;
    top: -25px;
    :disabled{
      background-color: #75B35A;
      border-color: #75B35A;
      opacity: 1;
    } 
  }
}

@media (min-width: 1580px){
  .recall-col-left{
    padding-left: 15px;
    padding-right: 15px;
    flex: 0 0 66.66666666666%;
    max-width: 66.66666666666%;
  }
  
  .recall-col-right{
    padding-left: 15px;
    padding-right: 15px;
    flex: 0 0 33.333333333%;
    max-width: 33.333333333%;
  }
}

@media (max-width: 1580px){
  .recall-col-left{
    padding-left: 5px;
    padding-right: 15px;
    flex: 0 0 66.66666666666%;
    max-width: 66.66666666666%;
  }
  
  .recall-col-right{
    padding-left: 5px;
    padding-right: 15px;
    flex: 0 0 33.333333333%;
    max-width: 33.333333333%;
  }
}

@media (max-width: 1510px){
  .recall-col-left{
    padding-left: 5px;
    padding-right: 10px;
    flex: 0 0 66.66666666666%;
    max-width: 66.66666666666%;
  }
  
  .recall-col-right{
    padding-left: 5px;
    padding-right: 10px;
    flex: 0 0 33.333333333%;
    max-width: 33.333333333%;
  }
}


@media (max-width: 1500px){
  .recall-col-left{
    padding-left: 15px;
    padding-right: 5px;
    flex: 0 0 60%;
    max-width: 60%;
  }
  
  .recall-col-right{
    padding-left: 5px;
    padding-right: 15px;
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media (max-width: 1320px){
  .recall-col-left{
    padding-left: 15px;
    padding-right: 5px;
    flex: 0 0 55%;
    max-width: 55%;
  }
  
  .recall-col-right{
    padding-left: 5px;
    padding-right: 15px;
    flex: 0 0 45%;
    max-width: 45%;
  }
}


@media (max-width: 1210px){
  .recall-col-left{
    padding-left: 8px;
    padding-right: 2px;
    flex: 0 0 55%;
    max-width: 55%;
  }
  
  .recall-col-right{
    padding-left: 2px;
    padding-right: 5px;
    flex: 0 0 45%;
    max-width: 45%;
  }
}



@media (max-width: 1450px){
  .dp-detail-group-checkbox-item-1{
    padding-left: 15px;
    padding-right: 15px;
  }
  .dp-detail-group-checkbox-item-2{
    padding-left: 0px;
    padding-right: 0px;
  }
  .dp-detail-group-checkbox-item-3{
    padding-left: 0px;
    padding-right: 0px;
  }
  .dp-detail-group-checkbox-item-4{
    padding-left: 0px;
    padding-right: 15px;
  }
}

@media (max-width: 1440px){
  .dp-detail-group-checkbox-item-1{
    padding-left: 15px;
    padding-right: 15px;
    flex: 0 0 33.333333333%;
    max-width: 33.333333333%;
  }
  .dp-detail-group-checkbox-item-2{
    flex: 0 0 20%;
    max-width: 20%;
    font-size: 0.8rem;
  }
  .dp-detail-group-checkbox-item-3{
    flex: 0 0 20%;
    max-width: 20%;
    font-size: 0.8rem;
  }
  .dp-detail-group-checkbox-item-4{
    flex: 0 0 26.6666666666666%;
    max-width: 26.6666666666666%;
  }
}


.btn-carepass{
  color: #fff !important;
  background-color: rgb(75, 146, 238) !important;
  border-color: rgb(75, 146, 238) !important;
}


.btn-carepass:hover{
  color: #fff !important;
  background-color: rgb(58, 128, 219) !important;
  border-color: rgb(58, 128, 219) !important;
}

.btn-carepass:not(:disabled):not(.disabled):active{
  color: #fff !important;
  background-color: rgb(58, 128, 219) !important;
  border-color: rgb(58, 128, 219) !important;
}