
.stepper-wrapper {
    font-family: Arial;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 10px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 10px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
  }
  
  .stepper-item.active {
    font-weight: bold;
    color: $danger;
  }

  .step-name{
    opacity: 0.5;
  }

  .step-name.active {
    font-weight: bold;
    color: $danger;
    opacity: 1;
  }

  .step-name.active.green {
    font-weight: bold;
    color: #4bb543;
    opacity: 1;
  }

  .step-name.active.lightgreen {
    font-weight: bold;
    color: #4bb543;
    opacity: 0.65;
  }

  .step-name.active.yellow {
    font-weight: bold;
    color: $warning;
    opacity: 1;
  }

  .step-name.active.red {
    font-weight: bold;
    color: $danger;
    opacity: 1;
  }

  .step-name.active.orange {
    font-weight: bold;
    color: chocolate;
    opacity: 1;
  }

  .step-name.active.black {
    font-weight: bold;
    color: var(--color);
    opacity: 1;
  }
  
  .step-name.active.blue {
    font-weight: bold;
    color: #0078D4;
    opacity: 1;
  }
  

  .stepper-item.completed .step-counter {
    background-color: #4bb543;
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 10px;
    left: 50%;
    z-index: 3;
  }

  .stepper-item.type-a .step-counter {
    background-color: #4bb543;
  }
  
  .stepper-item.type-a::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 10px;
    left: 50%;
    z-index: 3;
  }


  .stepper-item.type-b .step-counter {
    background-color: #4bb543;
  }

  .stepper-item.type-c::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 10px;
    left: 50%;
    z-index: 3;
  }

  .stepper-item-white-line::after{
    position: absolute;
    content: "";
    border-bottom: 2px solid #FFFFFF;
    width: 100%;
    top: 10px;
    left: 50%;
    z-index: 3;
  }
  
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
  


.react-datepicker-popper {
    z-index: 99 !important;
}